import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';

const CodeOfConductPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Code of Conduct</h2>
        <p>Wie man sich auf einem Barcamp verhält</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h2>Kurzum: Verhalte dich wie ein*e Erwachsene*r.</h2>
          <p>
            Alle Besucher*innen, Redner*innen, Sponsor*innen und Freiwillige auf
            diesem Barcamp werden aufgefordert, sich an folgende
            Verhaltensregeln zu halten. Diese werden vom Team auf der gesamten
            Veranstaltung durchgesetzt. Wir erbitten uns die Mitarbeit aller
            Anwesenden, um dieses Event zu einem sicheren und angenehmen Ort für
            alle zu machen.
          </p>
          <p>
            Wir möchten unseren Besucher*innen eine belästigungsfreie
            Veranstaltungserfahrung bieten, unabhängig von Geschlecht, sexueller
            Orientierung, Behinderung, Aussehen, Körperbau, Hautfarbe oder
            Religion. Wir tolerieren keinerlei Belästigung von Besuchern*innen.
            Sexualisierte Sprache und Darstellungen sind auf der Veranstaltung
            unerwünscht. Dies umfasst den Veranstaltungsort, die Vorträge und
            Workshops, sowie Twitter und andere Online-Medien. Teilnehmer*innen,
            die gegen diese Regeln verstoßen, müssen mit Sanktionen durch das
            Team rechnen, inklusive des Verweises von dieser und/oder allen
            zukünftigen Veranstaltungen.
          </p>
          <p>
            Bei Problemen kontaktiere:
            <ul>
              <li>
                {' '}
                <a href="mailto:brigitte.jellinek@fh-salzburg.ac.at">
                  Brigitte Jellinek
                </a>
              </li>
              <li>
                <a href="mailto:radomir.dinic@fh-salzburg.ac.at">
                  Radomir Dinic
                </a>
              </li>
            </ul>
          </p>
          <Link to="/">Zurück zur Startseite</Link>
        </div>
      </section>
    </article>
  </Layout>
);

export default CodeOfConductPage;
